// .tab{
//     position: absolute;
//     top: 56%;
//     left: 50%;
//     transform:translate(-50%,-50%) ;
//     width: 100%;
//     min-height: 100;
//     background-color: #f5f5f5;
// }

.table-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  margin-top: 20px;
  padding: 0 0rem 0 0rem;
}

.tab-item {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  flex-wrap: wrap;
  font-size: 0.85rem;
  cursor: pointer;
  // border: 1px solid #e31315;
}

#active {
  border-bottom: 5px solid #e31315 !important ;
  transition: all 0.3s ease;
}

.tab-item .fa-poll-h {
  padding-right: 0.3rem;
}

.tab-item .fa-history {
  padding-right: 0.3rem;
}

.tab-body-items {
  display: none;
  max-width: 100%;
}

#tab-body-items-active {
  display: unset;
}

.card-tab {
    max-width: 100% !important;
    background-color: #ffff;
   
    justify-content: space-between;
    border-radius: 0px;
    align-items: center;
    margin: 1rem .3rem;
    border: 1px solid #f2f2f2;
}



.card-tab-body{
    display: flex;
    align-items: center;
 padding: 1rem 1rem 1rem 1rem;
}

.card-tab-body{
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
}

.card-tab-body .fa-industry{
    color: #642667;
}

.card-tab-body .fa-calendar-alt{
    color: #642667;
}

.card-tab-body span{
    color: #642667;
    padding-left: 1rem;
}

.card-tab-body .card-tab-body-list{
    width: 100%;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    
}

.card-tab-footer .card-eye-footer{
    border: 2px solid #f2f2f2;
    height: fit-content;
    width: 50%;
    text-align: center;
    align-items: center;
}

.card-tab-footer .card-eye-footer-full{
    border: 2px solid #f2f2f2;
    height: fit-content;
    width: 100%;
    text-align: center;
    align-items: center;
}

#black-span{
    color: #333333;
    font-size: 14px;
}
#black-span.pointer{
    cursor: pointer;
    text-decoration: underline;
}



.card-tab-body img{
    width: 100px;
}

.card-tab-body label{
    padding-left: .3rem;
}

.card-tab-body span{
    color: #e31315;
}

.card-tab-footer{
    display: flex;
}

.card-progress-footer{
    width: 90%;
    height: 57px;
    border: 2px solid #f2f2f2;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
}

.card-progress-footer-full{
    width: 100%;
    height: 57px;
    border: 2px solid #f2f2f2;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    
}

.card-tab-footer .card-eye-footer.event{
    border: 2px solid #f2f2f2;
    height: 57px;
    width: 10%;
    text-align: center;
    align-items: center;
}

.card-eye-footer p{
    font-size: 12px;
}

.card-close-footer p{
    font-size: 12px;
}

#flex-body{
    padding-left: 1rem;
}

 #flex-body{
    color:#666666;
    font-size: 12px;
}

#flex-body label{
    cursor: pointer;
    text-decoration: underline;
}

.card-close-footer.event{
    border: 2px solid #f2f2f2;
    height: 57px;
    width: 10%;
    align-items: center;
    // display: flex;
    align-items: center;
    justify-content: center;
    padding:.6rem;
}

.card-close-footer .fa-times-circle{
    padding-right: .5rem;
}