.card-tab-footer-hr {
  border: 0.5px solid #dcdada;
}

.card-tab-footer-summary p {
  font-size: 0.85rem;
  padding: 0.5rem 0.5rem 0.5rem 0.9rem;
  font-weight: 600;
}

.card-tab-footer-flex {
  display: flex;
  align-items: center;
  justify-content: start;
}

.card-tab-footer-flex p {
  font-weight: 100;
  font-size: 0.8rem;
}

.half-circle {
  max-width: 100%;
  height: 200px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom: 0;
  background-color: #fff;
  margin: 1rem 1rem 0 1rem;
}
.summary-body {
  max-width: 100%;
  height: fit-content;
  display: block;
  background-color: #fff;
  margin: 0rem 1rem 0rem 1rem;
  padding: 1rem;
}

.summary-body #img-covidman {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -150px;
}

.summary-body h1 {
  text-align: center;
  color: #0d3e64;
}

.summary-body h2 {
  text-align: center;
  color: #0d3e64;
}
.summary-body #header {
  font-weight: 500;
  color: #333333;
  font-size: 18px;
  padding-top: 1rem;
}

.summary-body-content {
  padding: 1rem;
}

.summary-qr-code #img-qrcode {
  max-width: 70%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.summary-body-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  padding: 0 0rem 0 0rem;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.summary-body-footer span {
  padding-right: 1rem;
}

.summary-body-footer p {
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
  color: #0d3e64;
}

.summary-body-footer #solid-1 {
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.summary-body-footer #solid-2 {
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.card-tab-footer-hr2 {
  border: 0.5px solid #f2f2f2;
  margin-left: 1rem;
  margin-right: 1rem;
}
