.antd-login-user-layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background: #FFFFFF;
    background-image: url(../../Assets/background.svg);
  }
  .antd-pro-layouts-user-layout-content {
    flex: 1 1;
    padding: 100px 0;
  }
  
  .antd-pro-layouts-user-layout-top {
    text-align: center;
  }
  
  .antd-pro-layouts-user-layout-header {
    height: 44px;
    line-height: 44px;
  }

  .ant-select-selector{
    border-radius: 20px !important;
    padding: 8px 11px !important;
    height: 42px !important;
  }
